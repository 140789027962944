<template>
  <div class="order_manage">
    <div class="order_manage_nav">
      <div
        @click="changeNav(item.path)"
        v-for="(item, index) in list"
        :key="index"
        :class="[item.path == currentPath ? 'active' : '', 'nav-item']"
      >
        <div class="left_menu">
          <div class="name">
            {{ item.name }}
            <span v-if="item.name == '客户加盟'" class="customer_num">{{
              noVisit
            }}</span>
            <span v-if="item.name == '信息审核'" class="information_num">{{
              infoCheck
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="order_manage_content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPath: '/cloud-factory/cloud-factory-info',
      list: [
        {
          name: '客户加盟',
          path: '/cloud-factory/customer-joining',
          count_field: 'customer-joining',
        },
        {
          name: '云工厂信息',
          path: '/cloud-factory/cloud-factory-info',
          count_field: 'cloud-factory-info',
        },
        {
          name: '信息审核',
          path: '/cloud-factory/information-review',
          count_field: 'information-review',
        },
        {
          name: '新建云工厂',
          path: '/cloud-factory/add-factory',
          count_field: 'add-factory',
        },
      ],
    }
  },
  computed: {
    noVisit() {
      return this.$store.state.customer_count.no_visit
    },
    infoCheck() {
      return this.$store.state.info_check
    },
  },
  watch: {
    $route(to, from) {
      this.currentPath = to.path
    },
  },
  methods: {
    changeNav(path) {
      if (path != this.$route.path) {
        this.$router.push(path)
      }
    },
  },
  mounted() {
    this.currentPath = this.$route.path
    this.$store.dispatch('getCustomerCount')
    this.$store.dispatch('getInfocheck')
    // this.$store.dispatch('getOrderNum')
  },
}
</script>

<style lang="less" scoped>
.order_manage {
  padding: 0 32px;
  display: flex;
  height: 100%;
  .order_manage_nav {
    padding: 4px 0;
    width: 220px;
    height: 100%;
    background-color: #fff;
    margin-right: 2px;
    .nav-item {
      width: 100%;
      height: auto;
      padding: 20px 0 20px 30px;
      cursor: pointer;
      position: relative;
      .customer_num,
      .information_num {
        position: absolute;
        background: red;
        color: white;
        font-size: 12px;
        line-height: 12px;
        border-radius: 4px;
        padding: 1px 3px;
        top: 10px;
      }
      &.active {
        background-color: rgba(230, 247, 255);
        border-right: 4px solid #1890ff;
        color: #1890ff;
        .left_menu {
          .name {
            display: inline-block;
          }
        }
      }
    }
  }
  .order_manage_content {
    width: calc(100% - 220px);
    background-color: #fff;
  }
}
</style>
